import logoDefault from "../assets/images/LP-kingdom/logo.png"
import logoFooter from "../assets/images/LP-kingdom/logo-footer.svg"
import ImgEducacaoInfantil from "../assets/images/LP-kingdom/educacao-infantil.png"
import ImgEnsinoFundamentalI from "../assets/images/LP-kingdom/ensino-fundamental-1.png"
import ImgEnsinoFundamentalII from "../assets/images/LP-kingdom/ensino-fudamental-2.png"

import banner01 from "../assets/images/LP-kingdom/bg.png"
import mobileBanner01 from "../assets/images/LP-kingdom/bg-mobile.png"

export const LogoImage = {
  light: logoDefault,
};

export const LogoFooterImage = {
  light: logoFooter,
};

export const EducacaoInfantil = {
  light: ImgEducacaoInfantil,
};

export const EnsinoFundamentalI = {
  light: ImgEnsinoFundamentalI,
};

export const EnsinoFundamentalII = {
  light: ImgEnsinoFundamentalII,
};

export const NavLinks = [
  {
    name: "HOME",
    url: "/",
  },
  {
    name: "A ESCOLA",
    url: "/a-escola",
  },
  {
    name: "SEGMENTOS",
    url: "#segmentos",
    subItems: [
      {
        name: "EDUCAÇÃO INFANTIL",
        url: "/educacao-infantil",
      },
      {
        name: "ENSINO FUNDAMENTAL I",
        url: "/ensino-fundamental-i",
      },
      {
        name: "ENSINO FUNDAMENTAL II",
        url: "/ensino-fundamental-ii",
      },
    ]
  },
  {
    name: "CONTATO",
    url: "/fale-conosco",
  },
];

export const NavInnerLinks = [
  {
    name: "HOME",
    url: "/",
  },
  {
    name: "A ESCOLA",
    url: "/a-escola",
  },
  {
    name: "SEGMENTOS",
    url: "#segmentos",
    subItems: [
      {
        name: "EDUCAÇÃO INFANTIL",
        url: "/educacao-infantil",
      },
      {
        name: "EDUCAÇÃO FUNDAMENTAL I",
        url: "/ensino-fundamental-i",
      },
      {
        name: "EDUCAÇÃO FUNDAMENTAL II",
        url: "/ensino-fundamental-ii",
      },
    ]
  },
  {
    name: "CONTATO",
    url: "/fale-conosco",
  },
];

export const EliteData = [
  {
    countNumber: 156,
    aprovados: 'aprovados',
    curso: 'EsPCEx'
  },
  {
    countNumber: 81,
    aprovados: 'aprovados',
    curso: 'AFA'
  },
  {
    countNumber: 96,
    aprovados: 'aprovados',
    curso: 'EFOMM'
  },
];

export const SliderOneData = [
  {
    image: banner01,
    imageMobile: mobileBanner01,
    shape: 'shape01',
    title: "Escola além da escola",
    subTitle: "Educação Infantil e Ensino fundamental I e II",
    button: {
      label: "Agende uma visita",
      url: "fale-conosco",
    },
    
  },
];