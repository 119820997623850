/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import { LogoFooterImage } from "@/data";
import { footerSection, footerMenu } from "../assets/styles/AboutTwo.styles";
import { Link } from "gatsby";
import IcoInstagram from "../assets/icons/instagram-icon.svg";
import IcoFacebook from "../assets/icons/facebook-icon.svg";
import IcoYoutube from "../assets/icons/youtube-icon.svg";

const FooterPage = () => {
  return (
    <section css={footerSection}>
      <Container className='footer_desktop' >
        <Row gutter>
          <Col lg={5} md={5} sm={12}>
            <div className='col_one_container'>
              <div>
                <img src={LogoFooterImage.light} alt="" />
                <p>Uma escola autoral, afetuosa & internacional</p>
                <p style={{ fontWeight: 700 }}>Endereço</p>
                <p style={{ fontSize: 12 }}><span>Educação Infantil </span>- Setor de Habitações Individuais Sul EQL 6/8 Lago Sul, Brasília DF, CEP: 70040-906</p>
                <p style={{ fontSize: 12, marginBottom: 0 }}><span>Educação Fundamental </span> - Setor de Habitações Individuais Sul QI 11 área especial B, Entrequadras 02 e 04 - Brasília/DF - CEP: 71625-600 </p>
              </div>
              <div className='social_media_icons'>
                <a target='_blank' href='https://www.instagram.com/kingdomschooloficial/'><img src={IcoInstagram} alt="" /></a>
                <a target='_blank' href='https://www.facebook.com/kingdomschooldf'><img src={IcoFacebook} alt="" /></a>
                <a target='_blank' href='https://www.youtube.com/@KingdomSchool.Oficial'><img src={IcoYoutube} alt="" /></a>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Container>
              <Row>
                <Col lg={3} md={3}>
                  <Link className='title' to="/">Home</Link>
                </Col>
                <Col lg={3} md={3}>
                  <Link className='title' to="/a-escola">A Escola</Link>
                </Col>
                <Col className='segmentos_desktop' lg={3} md={3}>
                  <h3 className='title' >Segmentos</h3>
                  <Link className='link_desktop' to="/educacao-infantil">EDUCAÇÃO INFANTIL</Link>
                  <Link className='link_desktop' to="/ensino-fundamental-i">ENSINO FUNDAMENTAL I</Link>
                  <Link className='link_desktop' to="/ensino-fundamental-ii">ENSINO FUNDAMENTAL II</Link>
                </Col>
                <Col lg={3} md={3}>
                  <Link className='title' to="/fale-conosco">Fale Conosco</Link>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container className='footer_mobile'>
        <div className="logo_text">
          <img src={LogoFooterImage.light} alt="" />
          {/* <p>Uma escola autoral, afetuosa & internacional</p> */}
        </div>
        <div className="menu">
          <div className='segmentos_container'>
            <Link className='home' to="/">Home</Link>
            <div className='segmentos'>
              <h3>Segmentos</h3>
              <Link to="/educacao-infantil">EDUCAÇÃO INFANTIL</Link>
              <Link to="/ensino-fundamental-i">ENSINO FUNDAMENTAL I</Link>
              <Link to="/ensino-fundamental-ii">ENSINO FUNDAMENTAL II</Link>
            </div>
          </div>
          <div className='links'>
            <Link to="/a-escola">A Escola</Link>
            <Link to="/fale-conosco">Fale Conosco</Link>
          </div>
        </div>
        <div className='social_media' >
          <span>Siga a Kingdom:</span>
          <div>
            <a target='_blank' href='https://www.instagram.com/kingdomschooloficial/'>
              <img src={IcoInstagram} />
            </a>
            <a target='_blank' href='https://www.facebook.com/kingdomschooldf'>
              <img src={IcoFacebook} />
            </a>
            <a target='_blank' href='https://www.youtube.com/@KingdomSchool.Oficial'>
              <img src={IcoYoutube} alt="" />
            </a>
          </div>
        </div>
        <p style={{ fontWeight: 700 }}>Endereço</p>
        <p style={{ fontSize: 12 }}><span>Educação Infantil </span>- Setor de Habitações Individuais Sul EQL 6/8 Lago Sul, Brasília DF, CEP: 70040-906</p>
        <p style={{ fontSize: 12, marginBottom: 0 }}><span>Educação Fundamental </span> - Setor de Habitações Individuais Sul QI 11 área especial B, Entrequadras 02 e 04 - Brasília/DF - CEP: 71625-600 </p>
      </Container>
    </section>
  );
};

export default FooterPage;
