/** @jsx jsx */
import React, { useState, useEffect, useContext } from "react";
import { LogoImage, NavLinks } from "@/data";
import { Col, Container, Row } from "react-bootstrap";
import { SearchContext } from "@/context/search-context";
import { MenuContext } from "@/context/menu-context";
import { Link } from "gatsby";
import { jsx } from '@emotion/react'

import Instagram from '@/images/LP-teamsix/instagram.svg'
import Facebook from '@/images/LP-teamsix/facebook.svg'
import Youtube from '@/images/LP-teamsix/youtube.svg'
import MapLocationIcon from '../assets/icons/mapLocation-icon.svg'
// import Youtube from '@/images/LP-teamsix/youtube.svg'

import {
  header,
  logo,
  mainMenu,
  submenu,
  navigator,
  socialMedia,
  desktop,
  mobile,
  top_city
} from '../assets/styles/Header.styles'


const HeaderOne = () => {
  const [sticky, setSticky] = useState(false);
  const { searchStatus, updateSearchStatus } = useContext(SearchContext);
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const handleSearchClick = e => {
    e.preventDefault();
    updateSearchStatus(!searchStatus);
  };
  const handleMenuClick = e => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  return (
    <>
      <header css={header}
        className={`${true === sticky ? "fixedHeader" : null
          }`}
        id="header"
      >
        <div css={top_city}>
          <img src={MapLocationIcon} />
          <span>Brasília - DF | Lago Sul</span>
        </div>

        <Container
          style={{
            paddingTop: '8px'
          }}
        >
          <Row className="justify-content-between">
            <Col className="col-6" lg={2} md={3} sm={3}>
              <div css={logo}>
                <Link to="/">
                  <img src={LogoImage.light} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={8} sm={8} md={7} xs={7} className="d-none d-lg-block ">
              <nav css={mainMenu} className="text-center">
                <ul>
                  {NavLinks.map((links, index) => {
                    return (
                      <li
                        key={index}
                        className={`${undefined !== links.subItems
                          ? "menu-item-has-children"
                          : ""
                          }`}
                      >
                        <Link to={links.url}>{links.name}</Link>
                        {undefined !== links.subItems ? (
                          <ul css={submenu} className="sub-menu">
                            {links.subItems.map((subLinks, index) => (
                              <li key={index}>
                                <Link to={subLinks.url}>{subLinks.name}</Link>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </Col>
            <Col css={socialMedia} lg={2} sm={6} md={3} xs={3}>
              <nav className='nav-social'>
                <a target='_blank' href='https://www.instagram.com/kingdomschooloficial/'>
                  <img src={Instagram} alt="Instagram" />
                </a>
                <a target='_blank' href='https://www.facebook.com/kingdomschooldf'>
                  <img src={Facebook} alt="Facebook" />
                </a>
                <a target='_blank' href='https://www.youtube.com/@KingdomSchool.Oficial'>
                  <img src={Youtube} alt="Youtube" />
                </a>
              </nav>
            </Col>
            <Col css={mobile} lg={2} md={2} sm={4} xs={2}>
              <div css={navigator} className="text-right">

                <a
                  href="#"
                  className="menu mobilemenu d-none d-md-none d-lg-none"
                >
                  <i className="mei-menu"></i>
                </a>
                <a
                  id="open-overlay-nav"
                  className="menu hamburger"
                  onClick={handleMenuClick}
                  href="#"
                >
                  <i className="mei-menu"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};

export default HeaderOne;