/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "./layout.styles";

export const popUp = css`
  background: #5315FF;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
  opacity: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.6s 0s cubic-bezier(0.7, 0, 0.3, 1);
  width: 100%;
  z-index: 1000;
`;

export const showOverlayNav = css`
  overflow: hidden;

  .popup-inner {
    transform: translateY(0);
  }

  .popup.popup__menu {
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: visible;
    transform: translateY(0);
  }
`;

export const popUpMenu = css`
  background-repeat: repeat;
  background-position: center center;
  padding: 0;
`;

export const closepopUp = css`
  border-radius: 50%;
  font-weight: 600;
  height: 50px;
  width: 50px;
  position: absolute;
  right: 23px;
  top: 24px;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  z-index: 99;

  &:hover {
    /* box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2);
    -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2); */
    :after,
    :before {
      background: #ffffff;
    }
  }

  &:after {
    width: 20px;
    height: 2px;
    background: #ffffff;
    position: absolute;
    right: 0px;
    top: 8px;
    content: "";
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transition: all ease 400ms;
    -webkit-transition: all ease 400ms;
    -moz-transition: all ease 400ms;
  }

  &:before {
    width: 20px;
    height: 2px;
    background: #ffffff;
    position: absolute;
    right: 0px;
    top: 8px;
    content: "";
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transition: all ease 400ms;
    -webkit-transition: all ease 400ms;
    -moz-transition: all ease 400ms;
  }
`;

export const footerContacts = css`
  position: absolute;
  left: 0;
  bottom: 40px;
  margin: 0;
  padding-left: 0;

  li {
    list-style: none;
    display: block;
    font-size: 16px;
    color: #818181;
    line-height: 22px;
    margin: 0 0 7px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const popUpSocial = css`
  position: absolute;
  right: 0;
  bottom: 34px;
  padding: 0;

  ul {
    margin: 0;
    padding: 0;

    li {
      padding: 0;
      margin: 0 20px 0 0;
      list-style: none;
      display: inline-block;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        font-size: 28px;
        letter-spacing: 0.56px;
        color: #ffffff;
        margin: 0;
        display: block;
        text-align: left;

        &:hover {
          color: #fff;
        }

        i {
          font-size: 16px;
          color: #fff;
          margin-right: 10px;
        }
      }
    }
  }
`;

export const logo2 = css`
  z-index: 99;
  position: absolute;
  left: 0;
  top: 20px;

  img {
    width: 232px;
    padding-left: 15px;
  }
`;

export const popUpInner = css`
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  justify-content: space-between;
  transform: translateY(-100%);
  transition: all 0.6s 1000ms cubic-bezier(0.7, 0, 0.3, 1);
  width: 100%;
  padding-bottom: 15%;
`;

export const dlMenuWrap = css`
  height: auto;
  position: relative;
  perspective: 600px;
  perspective-origin: 50% -200%;
  text-align: left;
  transition: all 0.6s 0s cubic-bezier(0.7, 0, 0.3, 1);
  width: 100%;
  margin-bottom: 30px;
.expand_button {
  position: relative;
  top: -5px;
}
  button {
    border: none;
    background-color: transparent;
    outline: none;
    color: #fff;
  }

  ul {
    list-style: none;
    padding: 0;
    transform-style: preserve-3d;

    li {
      margin: 24px 0;
      position: relative;
      display: block;
      text-align: left;

      .menu-item-has-children > a {
        position: relative;
      }

      button {
        position: relative;
        transition: transform 500ms ease;

        :hover {
          transform: rotate(-180deg);
        }
      }

      a {
        font-family: "montserrat", sans-serif;
        color: #ffffff;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 36px */
        letter-spacing: -1.5px;
        position: relative;
        display: inline-block;
        text-decoration: none;
        -webkit-text-decoration-skip: objects;
        transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);

        &:hover {
          &::after {
            width: 100%;
          }
        }

        &::after {
          position: absolute;
          width: 0;
          height: 1px;
          content: "";
          background: #fff;
          left: 0;
          right: 0;
          bottom: -5px;
          -webkit-transition: all 0.6s 0s cubic-bezier(0.7, 0, 0.3, 1);
          -moz-transition: all 0.6s 0s cubic-bezier(0.7, 0, 0.3, 1);
          transition: all 0.6s 0s cubic-bezier(0.7, 0, 0.3, 1);
          z-index: -1;
        }
      }
    }
  }
`;

export const subMenu = css`
  display: none;
  &.hide {
    display: none;
  }
  &.show {
    display: block;
  }
`;

export const org = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    margin-bottom: 16px;
    width: 140px;
    img{
      width: 100%;
    }
  }
  p{
    font-size: 18px;
    margin-bottom: 32px;
  }
`;

export const top_city = css`
  height: 31px;
  background: #5315FF;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  span {
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }
`

export const socialMedia = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .nav-social {
    display: flex;
    align-items: center;
    @media (max-width: 500px) {
      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
  a{
    margin-left: 8px;
    img{
      width: 32px;
      &:hover{
        filter: invert(72%) sepia(39%) saturate(6287%) hue-rotate(384deg) brightness(112%) contrast(75%);
      }
    }
  }
  /* a:first-child {
    margin-right: 8px;
  } */
  /* a:nth-child(2) {
    margin-right: 24px;
    @media (max-width: 500px) {
      margin-right: 12px;
    }
  } */

  a:hover {
    path {
      stroke: #bc9bfa;
    }
    .facebookIcon {
      fill: #bc9bfa;
    }
  }
  a:active {
    path {
      stroke: #04aec9;
    }
    .facebookIcon {
      fill: #04aec9;
    }
  }

  .fale-btn {
    padding: 12px 22px;
    background-color: #6c63ff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    color: #f9f9f9;

    &:hover {
      background-color: #bc9bfa;
    }
    &:active {
      background-color: #04aec9;
    }
  }
  .mobile {
    display: flex;
    padding: 12px 16px;
    @media (min-width: 850px) {
      display: none;
    }
  }
  .desktop {
    display: flex;
    @media (max-width: 850px) {
      display: none;
    }
  }
`